import { IMoviesDatabaseRepository } from "../data/IMoviesDatabaseRepository";
import PaginatedReponse from "../entities/ReponseAPIMovies";
import WatchItem from "../entities/WatchItem";
import MediaType from "../entities/MediaType";
import { GetWatchItemsByMediaTypeUseCase } from "./interfaces/GetWatchItemsByMediaTypeUseCase";

export class GetWatchItemsByMediaTypeInteractor implements GetWatchItemsByMediaTypeUseCase {
  constructor(private movieDatabaseRepository: IMoviesDatabaseRepository) { }

  async execute(mediaType: MediaType | null, pageNumber: number): Promise<PaginatedReponse<WatchItem>> {
    switch (mediaType) {
      case MediaType.Movie:
        return this.movieDatabaseRepository.GetMovieDiscover(pageNumber);
      case MediaType.Serie:
        return this.movieDatabaseRepository.GetTvDiscover(pageNumber);
      default:
        throw 'Unspported MediaType';
    }
  }
}