import { useNavigate } from 'react-router-dom';
import WatchItem from '../../../domain/entities/WatchItem';
import { styles } from './styles';
import ImageIcon from "@mui/icons-material/Image";
import { useCallback, useEffect, useRef, useState } from 'react';
import { height } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Constants } from '../../../helpers/Constants';
interface Props {
    item: WatchItem
    onPress: () => void
}

export const JackettCard = ({ item, onPress }: Props) => {
    const isDesktop = useMediaQuery(Constants.Theme.BreakPointMobile) ? 5: 2;
    return (
        <div style={{ ...styles.card , ...{minHeight:isDesktop ? "calc(100vw/5)" : "calc(100vw/2*1.2)"}}} onClick={onPress}>
            {item.poster_path != null ? <img style={styles.jackettImg}  src={`https://image.tmdb.org/t/p/w500${item.poster_path}`} /> : 
            <div style={{ flex: 1, display: "flex", justifyContent: "center", "alignItems": "center", }}>
                <ImageIcon fontSize={'large'} />
            </div>}
        </div>
    )
}