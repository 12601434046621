import { useEffect, useState } from "react";
import { MovieDatabaseRepository } from "../../datasource/remote/MovieDatabaseRepository";
import Season from "../../domain/entities/Season";
import SerieDetails from "../../domain/entities/TVSerieDetails";

export const useSerieDetails = (serieId: number) => {
  const [serie, setSerie] = useState<SerieDetails | null>(null);
  const [seasons, setSeasons] = useState<Season[] | null>(null);
  const [video, setVideo] = useState(null);  
  const [isLoading, setIsLoading] = useState(true);
  const load = async () => {
      var serieDetails = await new MovieDatabaseRepository().GetSerieDetails(serieId);
      var videosResponse = await new MovieDatabaseRepository().GetSerieVideos(serieId);
      if (videosResponse.results.length >0)
        setVideo(videosResponse.results[0].key);
      var promises = serieDetails.seasons.map(s =>  new MovieDatabaseRepository().GetSeasonDetails(serieId, s.season_number))
      var results = await Promise.all(promises);
      setSerie(serieDetails);
      setSeasons(results.filter(x => x.season_number != 0));
      setIsLoading(false);
  }
  useEffect(() => {
    load();
  }, [serieId]);
  return {
    serie, isLoading, seasons,video
  }
}


