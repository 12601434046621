
import { useParams } from 'react-router';
import { Loader } from '../components/Loader';
import { useSerieDetails } from './useSerieDetails';
import { styles } from './styles';
import { EpisodeCard } from '../components/EpisodeCard';
import { Button, Card, Chip, useMediaQuery } from '@mui/material';
import { Constants } from '../../helpers/Constants';
import ReactPlayer from 'react-player'
import SearchIcon from "@mui/icons-material/Search";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { TvSerieInformations } from '../components/HeaderInformations';
import { useState } from 'react';

export interface Props {
    serieId?: number
}
export function SerieDetailsScreen(props: Props) {
    const { id } = useParams<{ id?: string }>();

    const { serie, isLoading, seasons, video } = useSerieDetails((props.serieId ?? Number(id)));
    const isDesktop = useMediaQuery(Constants.Theme.BreakPointMobile);
    const [muted, setMuted] = useState(true);
    if (isLoading) return (
        <div style={{ width: "100%", height: 900, padding: "3em", backgroundColor: Constants.Theme.BackgroundColor, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", }}>
            <Loader />
        </div>)


    if (!isDesktop) {
        return (<div>
            {video != null ? <div style={styles.player_wrapper}>
                <ReactPlayer
                    // className='react-player'
                    style={styles.react_player}
                    config={{
                        youtube: {
                            playerVars: {
                                showinfo: 1, modestbranding: 1,

                            },
                            embedOptions: {
                                frameborder: "0",
                                /*   height: '720',
                                   width: '100%', */
                            }
                        }
                    }}
                    url={`https://www.youtube.com/watch?v=${video}&showinfo=0`}
                    height="100%"
                    width="100%"
                    playing={true}
                    onReady={() => setTimeout(() => {
                        setMuted(false)
                    }, 1000)}
                    loop={true}
                    muted={muted}
                    controls={false} />
            </div> : <img style={styles.backdrop} src={`https://image.tmdb.org/t/p/w500${serie?.backdrop_path}`} />}
            <div style={{ ...styles.containerColumn, ...styles.container }}>
                <div style={styles.middlePanel}>
                    <h3>{serie?.name}</h3>
                    <TvSerieInformations seasons={seasons} serie={serie} />
                    <div style={{ ...styles.containerRow, ...styles.marginVertical }}>
                        <Button style={{ flex: 1, backgroundColor: Constants.Theme.Primary, fontWeight: "bold", color: "black" }} variant={"contained"} startIcon={<SearchIcon />}>{"Rechercher"}</Button>
                    </div>
                    <div style={{ ...styles.containerRow, ...styles.marginVertical }}>
                        <Button style={{ flex: 1, backgroundColor: Constants.Theme.SecondaryButton, fontWeight: "bold", color: "white" }} variant={"contained"} startIcon={<FavoriteIcon />}>{"Ajouter aux favoris"}</Button>
                    </div>
                    <div style={{ ...styles.containerRow, ...styles.marginVertical, ...styles.description }}>
                        <p>{serie?.overview}</p>
                    </div>
                    {seasons?.map(season => <div key={season.id} >
                        <h3>{season.name}</h3>
                        {season.episodes.map(episode => <EpisodeCard key={episode.id} serie={serie!} episode={episode}></EpisodeCard>)}</div>)}
                  
                </div>

            </div>
        </div>)
    }
    return (
        <>
            {video != null ? <div style={styles.player_wrapper}>
                <ReactPlayer
                    style={styles.react_player}
                    config={{
                        youtube: {
                            playerVars: {
                                showinfo: 1, modestbranding: 1,

                            },
                            embedOptions: {
                                frameborder: "0",
                            }
                        }
                    }}

                    url={`https://www.youtube.com/watch?v=${video}&showinfo=0`}
                    height={"100%"}
                    width="100%"
                    playing={true}

                    loop={true}
                    muted={muted}
                    controls={false} />
            </div> : <img style={styles.backdrop} src={`https://image.tmdb.org/t/p/original${serie?.backdrop_path}`} />}
            <div style={styles.descktopContainer}>


                <div style={styles.containerRow}>

                    <div style={styles.leftPanel}>
                        <TvSerieInformations serie={serie} seasons={seasons} />
                        <div style={{ ...styles.containerRow, ...styles.marginVertical, ...styles.description }}>
                            <p>{serie?.overview}</p>
                        </div>
                    </div>

                    <div style={styles.rightPanel}>


                    </div>

                </div>
                <div style={styles.containerRow}>
                    <div style={styles.middlePanel}>
                        {seasons?.map(season => <div key={season.id} >
                            <p>{season.name}</p>
                            {season.episodes.map(episode => <EpisodeCard key={episode.id} serie={serie!} episode={episode}></EpisodeCard>)}</div>)}

                    </div>
                </div>
            </div>
        </>
    )
}