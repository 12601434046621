
import {IMoviesDatabaseRepository } from '../../domain/data/IMoviesDatabaseRepository'
import MovieDetails from '../../domain/entities/MovieDetails'
import MovieItem from '../../domain/entities/MovieItem';
import PaginatedReponse from '../../domain/entities/ReponseAPIMovies';
import Season from '../../domain/entities/Season';
import SerieDetails from '../../domain/entities/TVSerieDetails';
import TvSerieItem from '../../domain/entities/TvSerieItem';
import WatchItem from '../../domain/entities/WatchItem';
import MediaType from '../../domain/entities/MediaType';
export class  MovieDatabaseRepository  implements IMoviesDatabaseRepository {
    HOST: string = "https://api.themoviedb.org";
    LANGUAGE="fr";
    API_TOKEN: string = (window as any)._env_.REACT_APP_THEMOVIEDATABASE_TOKEN || "";
    async SearchMulti(query: string, page:number): Promise<PaginatedReponse<WatchItem>> {
        const response = await fetch(`${this.HOST}/3/search/multi?api_key=${this.API_TOKEN}&query=${query}&page=${page}&language=${this.LANGUAGE}`)
        const items:PaginatedReponse<WatchItem> = (await response.json())
        items.results = items.results.filter(x => x.media_type == MediaType.Movie || x.media_type == MediaType.Serie)
        return items;
    }




    
    async  GetMovieVideos(movieId: number): Promise<any> {
        const response = await fetch(`${this.HOST}/3/movie/${movieId}/videos?api_key=${this.API_TOKEN}&language=${this.LANGUAGE}`)
        const movie = (await response.json())
        return movie;
    }
    
   
   async GetSeasonDetails(serieId: number, season_number:number): Promise<Season> {
        const response = await fetch(`${this.HOST}/3/tv/${serieId}/season/${season_number}?api_key=${this.API_TOKEN}&language=${this.LANGUAGE}`)
        const movie : Season= (await response.json())
        return movie;
    }
    async GetSerieDetails(serieId: number): Promise<SerieDetails> {
        const response = await fetch(`${this.HOST}/3/tv/${serieId}?api_key=${this.API_TOKEN}&language=${this.LANGUAGE}`)
        const movie : SerieDetails= (await response.json())
        return movie;
    }

    async GetSerieVideos(serieId: number): Promise<any> {
        const response = await fetch(`${this.HOST}/3/tv/${serieId}/videos?api_key=${this.API_TOKEN}&language=${this.LANGUAGE}`)
        const movie = (await response.json())
        return movie;
    }
    
    async GetTvDiscover (page : number) : Promise<PaginatedReponse<TvSerieItem>> {
        const response = await  fetch(`${this.HOST}/3/discover/tv?sort_by=popularity.desc&api_key=${this.API_TOKEN}&language=${this.LANGUAGE}&page=${page}`)
        const reponseAPITvSeries : PaginatedReponse<TvSerieItem> = (await response.json())
        reponseAPITvSeries.results.forEach(tv => tv.media_type = MediaType.Serie)
        return reponseAPITvSeries;
    }

    async GetMovieDiscover(page: number) : Promise<PaginatedReponse<MovieItem>> {
        const response = await fetch(`${this.HOST}/3/discover/movie?sort_by=popularity.desc&api_key=${this.API_TOKEN}&language=${this.LANGUAGE}&page=${page}`)
        const movies:  PaginatedReponse<MovieItem> = (await response.json())
        movies.results.forEach(movie => movie.media_type = MediaType.Movie)
        return movies;
    }
    
    async GetMovieDetails(movieId: number):Promise<MovieDetails> {
     
        const response = await fetch(`${this.HOST}/3/movie/${movieId}?api_key=${this.API_TOKEN}&language=${this.LANGUAGE}`)
        const movie : MovieDetails= (await response.json())
        return movie;
    }
}
