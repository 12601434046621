
import { AppBar, Button, Card, CardContent, DialogContent, Fade, IconButton, ListItemButton, ListItemIcon, Toolbar } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { TransitionProps } from '@mui/material/transitions';
import React, { useEffect } from 'react';
import { SerieDetailsScreen } from '../../SerieDetails';
import WatchItem from '../../../domain/entities/WatchItem';
import { MovieDetailScren } from '../../MovieDetails';
import MediaType from '../../../domain/entities/MediaType';
export interface Props {
    open: boolean;
    watchItem: WatchItem | null;
    onClose: () => void;
}
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Fade ref={ref} {...props} />;
});
const content = (watchItem: WatchItem | null) => {
    if ( watchItem == null) return (<></>)
    switch (watchItem?.media_type){
        case MediaType.Serie:
           return <SerieDetailsScreen serieId={watchItem!.id} />;
        case MediaType.Movie:
            return <MovieDetailScren movieId={watchItem!.id} />;
        default:
            throw new Error('Method not implemented.');
    }
   
}
export function WatchItemDialog(props: Props) {
    const { open, onClose, watchItem } = props;   
    return (
        <Dialog
            maxWidth={'lg'}
            fullWidth={true}
            open={open}
            onClose={onClose}
            scroll="body"
            TransitionComponent={Transition}
            transitionDuration={700}
            PaperProps={{style:{borderRadius:25, backgroundColor:'rgba(0, 0, 0, 0.5)'}}}
          
        >
         {content(watchItem)}
        </Dialog>
    );
}
