import { Chip } from '@mui/material';
import moment from 'moment';
import MovieDetails from '../../../domain/entities/MovieDetails';
import Season from '../../../domain/entities/Season';
import TVSerieDetails from '../../../domain/entities/TVSerieDetails';
import { Constants } from '../../../helpers/Constants';
import { styles } from './styles';
interface TvSerieInformationsProps {
    serie: TVSerieDetails | null,
    seasons: Season[] | null
}

export const TvSerieInformations = (props: TvSerieInformationsProps) => <>
    <div style={{ ...styles.containerRow, ...styles.marginVertical }}>
        <p style={{ color: Constants.Theme.RecommandedColor, fontWeight: "bolder", marginRight: 2 }}>Recommandé à {Math.round(props.serie?.vote_average! * 10)} % </p>
        <p style={{ fontWeight: "bold", marginRight: 5 }}>{moment(props.serie?.first_air_date).format("yyyy")}</p>
        <p>{props.seasons?.length} Partie{props.seasons!.length > 1 ? "" : "s"}</p>
    </div>
    {/*<div style={styles.containerRow}>
        {props.serie?.genres.map((genre: any) => <Chip label={genre.name}  variant="outlined" color={'primary'} />)}
</div>*/}
</>

interface MovieInformations {
    movie: MovieDetails | null,
}

export const MovieInformations = (props: MovieInformations) => <>
    <div style={{ ...styles.containerRow, ...styles.marginVertical }}>
        <p style={{ color: Constants.Theme.RecommandedColor, fontWeight: "bolder", marginRight: 2 }}>Recommandé à {Math.round(props.movie?.vote_average! * 10)} % </p>
        <p style={{ fontWeight: "bold", marginRight: 5 }}>{moment(props.movie?.release_date).format("yyyy")}</p>
        <p>{props.movie?.runtime} minutes</p>
    </div>
    {/*<div style={styles.containerRow}>
        {props.movie?.genres.map((genre: any) => <Chip label={genre.name}  variant="outlined" color={'primary'} />)}
    </div>*/}
</>