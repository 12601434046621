
import { useMediaQuery } from '@mui/material';
import { Constants } from '../../helpers/Constants';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useMemo } from "react";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import PaginatedReponse from "../../domain/entities/ReponseAPIMovies";
import WatchItem from '../../domain/entities/WatchItem';
import { debounce } from 'lodash';
import { hash } from '../../helpers/Helpers';
import WatchItemType from '../../domain/entities/MediaType';
import { GetWatchItemsByKeywordUseCase } from '../../domain/useCases/interfaces/GetWatchItemsByKeyWordUseCase';
import { GetWatchItemsByMediaTypeUseCase } from '../../domain/useCases/interfaces/GetWatchItemsByMediaTypeUseCase';

export const useViewModel = (keywordUsecase: GetWatchItemsByKeywordUseCase, mediaTypeUsecase: GetWatchItemsByMediaTypeUseCase) => {
    const isDesktop = useMediaQuery(Constants.Theme.BreakPointMobile);
    const [tabIndex, setTabIndex] = useState(0);
    const [query, setQuery] = useState<string>('');
    const [open, setOpen] = useState(false);
    const [watchItem, setWatchItemId] = useState<WatchItem | null>(null);
    const navigate = useNavigate();
    const handleClickOpen = (item: WatchItem) => {
        if (isDesktop) {
            setOpen(true);
            setWatchItemId(item);
        }
        else {
            navigate(`/${item.media_type}s/${item.id}`)
        }

    };
    const handleClose = () => setOpen(false);
    const mediaType = useMemo((() => query != '' ? null : (tabIndex == 0 ? WatchItemType.Movie : WatchItemType.Serie)), [query, tabIndex]);
    const queryKey = useMemo(() => mediaType == null ? hash(query) : "watchItems_" + mediaType, [query, mediaType]);

    const usecase = useMemo(() => mediaType == null ? (pageParam: number) => keywordUsecase.execute(query, pageParam) : (pageParam: number) => mediaTypeUsecase.execute(mediaType, pageParam), [query, mediaType]);
    const onChangeQuery = debounce((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setQuery(event.target.value)
    }, 300);


    const { data, error, fetchNextPage, status, hasNextPage, } = useInfiniteQuery(
        [queryKey],
        ({ pageParam = 1 }) => usecase(pageParam),
        {
            getNextPageParam: (lastPage: PaginatedReponse<WatchItem>) => {

                const previousPage = lastPage.page
                const currentPage = previousPage + 1;

                if (currentPage === lastPage.total_pages) return false;
                return currentPage + 1;
            }
        }
    )

    const watchItems = useMemo(() => data?.pages.reduce((previousValue: PaginatedReponse<WatchItem>, currentValue: PaginatedReponse<WatchItem>) => {
        var results = [...previousValue.results, ...currentValue.results];
        return {
            page: currentValue.page,
            total_results: results.length,
            total_pages: previousValue.total_pages,
            results: results,
        }
    }), [data, query])

    return {
        error, fetchNextPage, status, hasNextPage, handleClickOpen, handleClose, watchItems, watchItem, open, setQuery, query, tabIndex, setTabIndex, onChangeQuery
    }
}

