
import { AppBar, Button, Card, CardContent, IconButton, ListItemButton, ListItemIcon, Toolbar } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import CopyAllIcon from "@mui/icons-material/CopyAll";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { Constants } from '../../../helpers/Constants';
import { useViewModel } from './useViewModel';
import { Loader } from '../Loader';
import { TorrentRepository } from '../../../datasource/remote/TorrentRepository';
import { styles } from './styles';
import Torrent from '../../../domain/entities/Torrent';
import { humanFileSize } from '../../../helpers/Helpers';
import toast, { Toaster } from 'react-hot-toast';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export interface Props {
    open: boolean;
    kewwords: string[]
    mediaCategory: number;
    onClose: (value: string) => void;
}

export function SelectTorrentDialog(props: Props) {
    const { onClose, open, kewwords, mediaCategory } = props;
    const { torrents, isLoading, load } = useViewModel(kewwords, mediaCategory);
    const handleClose = () => {
        onClose("");
    };

    const copyLinkToClipBoard = (torrent: Torrent) =>{

        navigator.clipboard.writeText(torrent.link);
        toast('Lien Torrent copié');
    }
    
    const downloadTorrent = async (torrent: Torrent) => {
        toast.promise(
            new TorrentRepository().DowloadTorrents(torrent.link, mediaCategory),
             {
               loading: 'Scanning...',
               success: <b>The download has started</b>,
               error: <b>The download cannot start.</b>,
             }
           );
    }
    useEffect(() => {
        if (open) {
            load();
        }
        console.warn(open);
    }, [open])
    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative', backgroundColor: Constants.Theme.BackgroundColor }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Sound
                    </Typography>
                    <Button color="error" onClick={handleClose}>
                        save
                    </Button>
                </Toolbar>
            </AppBar>
            {isLoading && <Loader />}
            {!isLoading && <List dense={true} >
                {torrents?.map(torrent => <ListItem
                key={torrent.guid}
                secondaryAction={
                    <IconButton onClick={() => copyLinkToClipBoard(torrent)}>
                        <CopyAllIcon />
                    </IconButton>
                }>
                    <ListItemButton   onClick={() => downloadTorrent(torrent)} >
                        <ListItemIcon>
                            <CloudUploadIcon />
                        </ListItemIcon>
                        <ListItemText primary={torrent.title} secondary={torrent.tracker + " | " + humanFileSize(torrent.size) + " | Seed:" + torrent.seeders + " | Leachers:" + torrent.peers} style={styles.listItem}/>
                    </ListItemButton>

                </ListItem>
           )}

            </List>}
            <Toaster />
        </Dialog>
    );
}
