import { CSSProperties } from "react";


const card = {
    margin: "1px",
    border: "2px solid rgba(57, 56, 56, 0.568)",
    backgroundColor: "#242424",
    borderRadius: "7px",
    overflow: "hidden",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: "5px 5px 25px black",
    animation: "fadeInMove 1s ease"
} as CSSProperties;

const jackettImg ={
    width: "100%",
    height:"100%",
    animation: "fadeIn 1s ease-in-out"
} as CSSProperties;

export const styles = { card,jackettImg };