
import { CSSProperties } from "react";
const containerMobile = {
  display: "grid",
  gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
} as CSSProperties;

const containerDesktop = {
  padding:5,
  display: "grid",
  gridTemplateColumns: "repeat(5, minmax(0, 1fr))",
} as CSSProperties;

export const styles  ={ containerDesktop,containerMobile }