
import { Button, Card, Grid, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { JackettCard } from '../components/JackettCard';
import { Loader } from '../components/Loader';
import { SelectTorrentDialog } from '../components/SelectTorrentDialog';
import { useViewModel } from './useViewModel';
import MediaCategory from '../../domain/entities/MediaCategory';
import MovieDetails from '../../domain/entities/MovieDetails';
import { MovieInformations } from '../components/HeaderInformations';
import { styles } from './styles';
import ReactPlayer from 'react-player';
import { GetMovieDetailsInteractor } from '../../domain/useCases/GetMovieDetailsInteractor';
import { MovieDatabaseRepository } from '../../datasource/remote/MovieDatabaseRepository';
import SearchIcon from "@mui/icons-material/Search";
import Fab from '@mui/material/Fab';
import { Constants } from '../../helpers/Constants';
import FavoriteIcon from "@mui/icons-material/Favorite";
interface Props {
    movieId?: number;
}
function GetKeyWords(movie: MovieDetails): string[] {
    var keywords = [`${movie.title}`, `${movie.original_title}`]
    return keywords;
}


function DesktopView(movie: MovieDetails, onClick: () => void) {
    return ( <>
    {movie?.video ? <div style={styles.player_wrapper}>
        <ReactPlayer
            style={styles.react_player}
            config={{
                youtube: {
                    playerVars: {
                        showinfo: 1, modestbranding: 1,

                    },
                    embedOptions: {
                        frameborder: "0",
                    }
                }
            }}
            url={`https://www.youtube.com/watch?v=${movie.teaser}&showinfo=0`}
            height={"100%"}
            width="100%"
            playing={true}
            loop={true}
            muted={true}
            controls={false} />
    </div> : <img style={styles.backdrop} src={`https://image.tmdb.org/t/p/original${movie?.backdrop_path}`} />}
    <div style={styles.descktopContainer}>
         <h3>{movie?.title}</h3>
        <div style={styles.containerRow}>
            <MovieInformations movie={movie} />
        </div>
        <div style={styles.containerRow}>
            <div style={{ ...styles.containerRow, ...styles.marginVertical, ...styles.description }}>
                <p>{movie?.overview}</p>
            </div>
            <div style={{ ...styles.containerRow, ...styles.centerContainer}}>
                <Fab color="primary" aria-label="add" onClick={onClick}>
                    <SearchIcon />
                </Fab>
            </div>
        </div>

    </div>
</>);
}




function MobileView(movie: MovieDetails, onClick: () => void, muted: boolean, setMuted : (state: boolean) => void) {
    return  (<div>
        {movie.teaser != null ? <div style={styles.player_wrapper}>
            <ReactPlayer
                // className='react-player'
                style={styles.react_player}
                config={{
                    youtube: {
                        playerVars: {
                            showinfo: 1, modestbranding: 1,

                        },
                        embedOptions: {
                            frameborder: "0",
                            /*   height: '720',
                               width: '100%', */
                        }
                    }
                }}
                url={`https://www.youtube.com/watch?v=${movie.video}&showinfo=0`}
                height="100%"
                width="100%"
                playing={true}
                onReady={() => setTimeout(() => {
                    setMuted(false)
                }, 1000)}
                loop={true}
                muted={muted}
                controls={false} />
        </div> : <img style={styles.backdrop} src={`https://image.tmdb.org/t/p/w500${movie?.backdrop_path}`} />}
        <div style={{ ...styles.containerColumn, ...styles.container }}>
            <div style={styles.middlePanel}>
                <h3>{movie?.title}</h3>
                <MovieInformations movie={movie} />
                <div style={{ ...styles.containerRow, ...styles.marginVertical }} >
                    <Button style={styles.searchButton} variant={"contained"} onClick={onClick} startIcon={<SearchIcon />}>{"Rechercher"}</Button>
                </div>
                <div style={{ ...styles.containerRow, ...styles.marginVertical }}>
                    <Button style={styles.addFavoriteButton} variant={"contained"} startIcon={<FavoriteIcon />}>{"Ajouter aux favoris"}</Button>
                </div>
                <div style={{ ...styles.containerRow, ...styles.marginVertical, ...styles.description }}>
                    <p>{movie?.overview}</p>
                </div>
            </div>
        </div>
    </div>)
}

export function MovieDetailScren(props: Props) {
    const { id } = useParams<{ id?: string }>();
    const movieId = props.movieId ?? Number(id);
    const { movie, isLoading, handleClose, open, handleClickOpen, muted, setMuted } = useViewModel(movieId, new GetMovieDetailsInteractor(new MovieDatabaseRepository()));
    const isDesktop = useMediaQuery(Constants.Theme.BreakPointMobile);
    if (isLoading) return <Loader />
    return (
        <>
            {isDesktop ? DesktopView(movie, handleClickOpen) : MobileView(movie,handleClickOpen, muted, setMuted)}
            <SelectTorrentDialog
                open={open}
                onClose={handleClose}
                kewwords={GetKeyWords(movie!)}
                mediaCategory={MediaCategory.Movie} />
        </>
    )
}