import { IMoviesDatabaseRepository } from "../data/IMoviesDatabaseRepository";
import PaginatedReponse from "../entities/ReponseAPIMovies";
import WatchItem from "../entities/WatchItem";
import { GetWatchItemsByKeywordUseCase } from "./interfaces/GetWatchItemsByKeyWordUseCase";


export class GetWatchItemsByQueryInteractor implements GetWatchItemsByKeywordUseCase {
  constructor(private movieDatabaseRepository: IMoviesDatabaseRepository) { }

  async execute(query: string, pageNumber: number): Promise<PaginatedReponse<WatchItem>> {
    return this.movieDatabaseRepository.SearchMulti(query, pageNumber);
  }
}