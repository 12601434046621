import React from "react";
import Paper from "@mui/material/Paper";
import BottomNavigation from "@mui/material/BottomNavigation";
import MuiBottomNavigationAction from "@mui/material/BottomNavigationAction";
import MovieIcon from "@mui/icons-material/Movie";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import SettingsIcon from "@mui/icons-material/Settings";
import { styled } from "@mui/material/styles";
import { Constants } from "../../../helpers/Constants";

const BottomNavigationAction = styled(MuiBottomNavigationAction)(`
  color: white;
  &.Mui-selected {
    color: red;
  }
`);

interface Props {
  currentValue : number,
  onChange : (event: React.SyntheticEvent, value: any) => void;
}
function AppBottomNavigation (props: Props) {
    return (
      <Paper  sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, }} elevation={3}>
        <BottomNavigation value={props.currentValue} showLabels={true} onChange={props.onChange} style={{backgroundColor: Constants.Theme.BackgroundColor}}>
          <BottomNavigationAction label="Films" icon={<MovieIcon />} />
          <BottomNavigationAction label="Séries TV" icon={<LiveTvIcon />} />
          <BottomNavigationAction label="Réglages" icon={<SettingsIcon />} />
        </BottomNavigation>
      </Paper>
    );
}
export default AppBottomNavigation;