import * as React from 'react';
import Box from '@mui/material/Box';
import AppBottomNavigation from './presentation/components/AppBottomNavigation';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import AppTopBar from './presentation/components/AppTopBar';
import { useViewModel } from './presentation/WatchItems/viewModel';
import InfiniteScroll from 'react-infinite-scroll-component';
import { WatchItemDialog } from './presentation/components/WatchItemDialog';
import { JackettFragment } from './presentation/components/JackettFragment';
import {  GetWatchItemsByMediaTypeInteractor } from './domain/useCases/GetMoviesInteractor';
import { MovieDatabaseRepository } from './datasource/remote/MovieDatabaseRepository';
import { Loader } from './presentation/components/Loader';
import MediaType from './domain/entities/MediaType';
import { GetWatchItemsByQueryInteractor } from './domain/useCases/GetWatchItemsByKeywordUseCase';
import { useAppBarHeight } from './helpers/Helpers';


export default function App() {
  const { watchItems, error, fetchNextPage, hasNextPage, status, watchItem, open, handleClose, handleClickOpen, onChangeQuery, setTabIndex, tabIndex } = useViewModel(new GetWatchItemsByQueryInteractor(new MovieDatabaseRepository()), new GetWatchItemsByMediaTypeInteractor(new MovieDatabaseRepository()));
  const appBarHeight = useAppBarHeight();
  const onChangeTab = (event: React.SyntheticEvent, value: any) => {
    setTabIndex(value);
  };


  return (
    <Box sx={{ pb: 7 }} >
      <AppTopBar onChange={onChangeQuery} />
      <div style={{ height: appBarHeight }} />
      {status == "loading" ? <Loader></Loader> :
        <InfiniteScroll
          dataLength={watchItems ? watchItems.results.length : 0}
          next={() => fetchNextPage()}
          hasMore={!!hasNextPage}
          loader={null}
        >
          <JackettFragment handleClickOpen={handleClickOpen} childrens={watchItems?.results}></JackettFragment>
        </InfiniteScroll>}
      <AppBottomNavigation currentValue={tabIndex} onChange={onChangeTab} />
      <WatchItemDialog
        open={open}
        onClose={handleClose} watchItem={watchItem} />
    </Box>
  );
}
