export const Constants ={
    Theme:{
        BackgroundColor: '#141414',
        Primary:'white',
        SecondaryButton:'#414141',
        RecommandedColor:"#46d369",
        FontColor:"white",
        BreakPointMobile:"(min-device-width: 480px)"
    }
} ;
