import { ITorrentRepository } from '../../domain/data/ITorrentRepository';
import MediaCategory from '../../domain/entities/MediaCategory';
import Torrent from '../../domain/entities/Torrent';
import TransmissionDownloadResponse from '../../domain/entities/TransmissionDownloadResponse';

export class TorrentRepository implements ITorrentRepository {
    //host: string = "http://localhost:8091";
    host: string = (window as any)._env_.REACT_APP_BACKEND_HOST || "http://localhost:8091";
    async GetTorrents(keywords: string[], category: MediaCategory): Promise<Torrent[]> {
        var url = `${this.host}/api/Torrents/?category=${category}`;
        const fetchs = await Promise.all(keywords.map(keyword => fetch(`${url}&keyword=${keyword}`)));
        const validFetchs = fetchs.filter(promise => promise.status == 200);
        const results = await Promise.all(validFetchs.map(x => x.json()));
        var output: Torrent[] = []
        results.forEach(element => {
            output = output.concat(element);
        });
        const uniqueTorrents: Torrent[] = [...new Map(output.map(person => [person.title, person])).values()];
        return Promise.resolve(uniqueTorrents);
    }

    async DowloadTorrents(url: string, category: MediaCategory): Promise<TransmissionDownloadResponse> {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        
        var raw = JSON.stringify({
          "Category":category,
          "Url": url
        });
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        } as RequestInit;
        
        var response = await fetch(`${this.host}/api/Torrents/download`, requestOptions)

        var json = await response.json();
        const output: TransmissionDownloadResponse = json as TransmissionDownloadResponse;
        if (output.sucess){
          return Promise.resolve(output);
        }
        else {
          return Promise.reject(output);
        }
      
    }
}
