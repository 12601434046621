import { useEffect, useState } from "react";
import MovieDetails from "../../domain/entities/MovieDetails";
import MediaType from "../../domain/entities/MediaType";
import { GetMovieDetailsUseCase } from "../../domain/useCases/interfaces/GetMovieDetailsUseCase";



export const useViewModel = (movieId: number, getMovieDetailsUseCase: GetMovieDetailsUseCase) => {
  const [movie, setMovie] = useState<MovieDetails>({
    media_type: MediaType.Movie,
    name: "",
    adult: false,
    backdrop_path: "/bQXAqRx2Fgc46uCVWgoPz5L5Dtr.jpg",
    budget: 200000000,
    homepage: "",
    id: movieId,
    imdb_id: "tt6443346",
    original_language: "en",
    original_title: "Black Adam",
    overview: "Près de 5000 ans après avoir été doté des pouvoirs tout puissants des dieux égyptiens – et emprisonné très rapidement après – Black Adam est libéré de sa tombe terrestre, prêt à faire régner sa forme unique de justice dans le monde moderne.",
    popularity: 3666.165,
    poster_path: "/5aGvMSMfE6BqQXR3mG46zjgX8V8.jpg",
    release_date: "2022-10-19",
    revenue: 5000000,
    runtime: 125,
    status: "Released",
    tagline: "Le monde change. Les héros aussi.",
    title: "Black Adam",
    video: false,
    vote_average: 7.36,
    vote_count: 236,
    teaser: "",
    genres:[]
  });
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [muted, setMuted] = useState(true);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    let isCancelled = false
    if (isCancelled === false){
      getMovieDetailsUseCase.execute(movieId)
      .then(response => {
        setMovie(response);
        setIsLoading(false)
      })
      .catch(error => {
        setIsLoading(false);
      });
    }
    return () => { isCancelled = true }
  }, [movieId]);
  return {
    movie, isLoading, open, handleClickOpen, handleClose, muted, setMuted
  }
}


