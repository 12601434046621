import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Stack } from '@mui/system';

export function Loader() {
  return (
     <Stack sx={{ color: 'grey.500' }} spacing={2} direction="row" justifyContent={"center"} paddingY={5}>
        <CircularProgress color="inherit" />
      </Stack>
  );
}
