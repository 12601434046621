
import { useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import WatchItem from '../../../domain/entities/WatchItem';
import { Constants } from '../../../helpers/Constants';
import { JackettCard } from '../JackettCard';
import { WatchItemDialog } from '../WatchItemDialog';
import { styles } from './styles';
interface Props {
    childrens?: WatchItem[];
    handleClickOpen: (item: WatchItem) => void
}
export function JackettFragment (props: Props) {
    const isDesktop = useMediaQuery(Constants.Theme.BreakPointMobile);

  return (
    <div style={isDesktop? styles.containerDesktop : styles.containerMobile}>
    {
      props.childrens && props.childrens.map(item => (
        <JackettCard key={item.id} item={item} onPress={() =>props.handleClickOpen(item)} />
      ))
    }
  </div>
  );
}

