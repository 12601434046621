import { CSSProperties } from "react";


const containerRow = {
    display: "flex",
    flexDirection: "row"
} as CSSProperties;
const marginVertical = {
    marginTop:10,
    marginBottom: 10
} as CSSProperties;

export const styles = { containerRow,marginVertical };