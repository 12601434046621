import { useNavigate } from 'react-router-dom';
import { Constants } from '../../../helpers/Constants';
import Episode from '../../../domain/entities/Episode';
import { useState } from 'react';
import { SelectTorrentDialog } from '../SelectTorrentDialog';
import { styles } from './styles';
import { useMediaQuery } from '@mui/material';
import SerieDetails from '../../../domain/entities/TVSerieDetails';
import { zeroPad } from '../../../helpers/Helpers';
import MediaCategory from '../../../domain/entities/MediaCategory';
import SearchIcon from "@mui/icons-material/Search";

const defaultPic = "https://www.themoviedb.org/assets/2/v4/glyphicons/basic/glyphicons-basic-38-picture-grey-c2ebdbb057f2a7614185931650f8cee23fa137b93812ccb132b9df511df1cfac.svg";
interface Props {
    serie: SerieDetails,
    episode: Episode
}

function GetKeyWords(season: SerieDetails, episode: Episode): string[] {
    var keywords = [`${season.name} S${zeroPad(episode.season_number, 2)}`, `${season.name} S${zeroPad(episode.season_number, 2)}E${zeroPad(episode.season_number, 2)} `]
    console.warn(keywords);
    return keywords;
}
export const EpisodeCard = ({ serie, episode }: Props) => {
    const isDesktop = useMediaQuery(Constants.Theme.BreakPointMobile);
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value: string) => {
        setOpen(false);
    };
    return (
        <>
        <div style={styles.container} onClick={handleClickOpen}>
            <div style={styles.containerRow}>
                <div>
                    <img style={{ ...styles.episodeThumbnail, ...styles.sectionRowEpisode }} src={episode.still_path ? `https://image.tmdb.org/t/p/w500${episode.still_path}` : defaultPic} loading='lazy' />
                </div>
                <div style={{ ...styles.containerTitle, ...styles.sectionRowEpisode }}>
                    {`${episode.episode_number}. Episode ${episode.episode_number}`}
                </div>
                <div style={{ ...styles.containerIcon, ...styles.sectionRowEpisode }}>
                    <SearchIcon></SearchIcon>
                </div>

            </div>
            <div style={styles.containerRow}>
                <p style={styles.description}> {episode.overview.length > 150 ?
                    `${episode.overview.substring(0, 150)}...` : episode.overview
                }</p>
            </div>
        </div>
         <SelectTorrentDialog
         open={open}
         onClose={handleClose} kewwords={GetKeyWords(serie, episode)} mediaCategory={MediaCategory.TV} />
         </>
    )
}