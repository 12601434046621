import { CSSProperties } from "react";

const leftPanel = {
    marginLeft: 5,
    marginRight: 5,
} as CSSProperties;


const container = {
  marginBottom:"1rem",
  marginTop:"1rem"

} as CSSProperties;
const rightPanel = {
    flex: 2
} as CSSProperties;

const seasonCard = {
    border: 2,
    boxShadow: {

    },
    flex: 1,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    marginBottom: 5,
} as CSSProperties;
const title = {
} as CSSProperties;

const description = {
    fontStyle: "italic",
    textAlign: "justify"
} as CSSProperties;

const sectionRowEpisode = {
    height: "5rem",
    margin: "0.2rem",
} as CSSProperties;
const episodeThumbnail = {
    flex: 1,
    objectFit: "contain",
    borderRadius: 10
} as CSSProperties;
const containerRow = {
    display: "flex",
    alignContent: "space-between"
} as CSSProperties;

const containerTitle = {
    display: "flex",
    flex: 2,
    alignItems: "center",

} as CSSProperties;

const containerIcon = {
    display: "flex",
    flex: 1,
    alignItems: "center",

} as CSSProperties;
export const styles = {container, sectionRowEpisode, containerIcon, containerRow, containerTitle, seasonCard, description, leftPanel, rightPanel, episodeThumbnail, title };