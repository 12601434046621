import { useEffect, useState } from "react";
import { TorrentRepository } from "../../../datasource/remote/TorrentRepository";
import MediaCategory from "../../../domain/entities/MediaCategory";
import Torrent from "../../../domain/entities/Torrent";
export const useViewModel = (keywords: string[], category: MediaCategory) => {
    const [torrents, setTorrents] = useState<Torrent[] | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const load = () => new TorrentRepository().GetTorrents(keywords, category)
        .then(response => {
            setTorrents(response);
            setIsLoading(false)
        })
        .catch(error => {
            setIsLoading(false);
        });

    return {
        torrents, isLoading, load
    }
}
