import { IMoviesDatabaseRepository } from "../data/IMoviesDatabaseRepository";
import MovieDetails from "../entities/MovieDetails";
import { GetMovieDetailsUseCase } from "./interfaces/GetMovieDetailsUseCase";


export class GetMovieDetailsInteractor implements GetMovieDetailsUseCase {
  constructor(private movieDatabaseRepository: IMoviesDatabaseRepository) {}

  async execute(movieId: number): Promise<MovieDetails> {
    var  movieDetail = await this.movieDatabaseRepository.GetMovieDetails(movieId);
    if (movieDetail.video){
      var videosResponse = await this.movieDatabaseRepository.GetMovieVideos(movieId);
      movieDetail.teaser = videosResponse.results[0].key;
    }
    return movieDetail;
  }
}