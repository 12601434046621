
import { CSSProperties } from "react";
import { Constants } from "../../helpers/Constants";
const containerRow = {
    display: "flex",
    flexDirection: "row"
} as CSSProperties;
const containerColumn= {
    display: "flex",
    flexDirection: "column"
} as CSSProperties;
const centerContainer = {
    width: "100%",
    justifyContent: "center",
    alignItems: "center" 
} as CSSProperties;
const container = {
    paddingLeft:5,
    paddingRight:5,
} as CSSProperties;
const leftPanel = {
    flex:1,
  width:"100%"
} as CSSProperties;
const marginVertical = {
        marginTop:10,
        marginBottom: 10
} as CSSProperties;

const description = {
textAlign:"justify"
} as CSSProperties;
const leftContainer ={
    position:"fixed"
} as CSSProperties
const rightPanel = {
    flex:2,

} as CSSProperties;
const middlePanel = {
    width: "100%",
} as CSSProperties;
const jackett = {
  //  width:"100%",
   // height: "720px",
    objectFit: "contain"
} as CSSProperties;
const backdrop = {
    width:"100%",
    objectFit: "contain"
} as CSSProperties;
const player_wrapper = {
    position:"relative",
    paddingTop:"56.25%" /* 720 / 1280 = 0.5625 */
  } as CSSProperties;
  const react_player = {
    position:"absolute",
    top: 0,
    left: 0,
  } as CSSProperties;
const searchButton = {
    flex: 1, backgroundColor: Constants.Theme.Primary, fontWeight: "bold", color: "black" 
} as CSSProperties;
const addFavoriteButton = {
    flex: 1, backgroundColor: Constants.Theme.SecondaryButton, fontWeight: "bold", color: "white" 
} as CSSProperties;
const descktopContainer = {
    paddingLeft:"3em",
    paddingRight:"3em",
    paddingBottom:"3em",
    backgroundColor:Constants.Theme.BackgroundColor,
    color:"white"
} as CSSProperties;
export const styles = {
    description,
    backdrop,
    leftContainer,
    containerRow,
    containerColumn,
    container,
    leftPanel,
    rightPanel,
    jackett,
    middlePanel,
    player_wrapper,
    react_player,
    marginVertical,
    descktopContainer,
    centerContainer,
    searchButton,
    addFavoriteButton
} 